<template>
  <div>
    <div v-if="pleaseWait" class="mt-3">
      <PleaseWait></PleaseWait>
    </div>
    <v-container class="my-1" v-if="service">
      <v-snackbar
        v-model="snackbar"
        top
        center
        color="success lighten-5 success--text text--darken-3"
        :timeout="4000"
      >
        <span>{{ $t("service details updated successfully") }}!</span>
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="success darken-3"
            @click="snackbar = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <v-row justify="space-between">
        <v-row justify="space-between">
          <v-col cols="12" md="6">
            <h2 class="headline text-capitalize">
              {{ $t("update service details") }}
            </h2>
            <small>{{ $t("click on required field to edit") }}.</small>
          </v-col>

          <v-col cols="12" md="6" align-self="end" class="text-right">
            <v-btn
              small
              dark
              :loading="loading"
              :color="$store.state.secondaryColor"
              @click="saveUpdate"
            >
              <v-icon small left>save</v-icon>
              <span class="caption text-capitalize">{{
                $t("save changes")
              }}</span>
            </v-btn>
          </v-col>
        </v-row>

        <v-alert>
          <v-form ref="form">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-row dense class="caption">
                    <v-col cols="12" md="4">
                      <span class="font-weight-medium text-capitalize"
                        >{{ $t("name of service") }}* :
                      </span>
                      <v-textarea
                        outlined
                        dense
                        auto-grow
                        rows="1"
                        v-model="service.name"
                        :rules="rules.emptyField"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12" md="4">
                      <span class="font-weight-medium text-capitalize"
                        >{{ $t("category of service") }}* :
                      </span>
                      <v-textarea
                        outlined
                        dense
                        auto-grow
                        rows="1"
                        v-model="service.category"
                        :rules="rules.emptyField"
                      ></v-textarea>
                    </v-col>

                    <v-col cols="12" md="4">
                      <span class="font-weight-medium text-capitalize">
                        {{ $t("description") }} * :
                      </span>
                      <v-textarea
                        outlined
                        dense
                        auto-grow
                        rows="1"
                        v-model="service.description"
                        :rules="rules.emptyField"
                      ></v-textarea>
                    </v-col>

                    <v-col cols="12" md="4">
                      <span class="font-weight-medium"
                        >{{ $t("selling price") }}* :
                      </span>
                      <v-text-field
                        @keypress="digits"
                        outlined
                        dense
                        v-model.trim="service.price.selling"
                        :rules="rules.number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <span class="font-weight-medium"
                        >{{ $t("limit price") }}* :
                      </span>
                      <v-text-field
                        @keypress="digits"
                        outlined
                        dense
                        v-model.trim="service.price.limit"
                        :rules="rules.number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <span class="font-weight-medium"
                        >{{ $t("discount") }}* :
                      </span>
                      <v-text-field
                        outlined
                        dense
                        @keypress="digits"
                        v-model.trim="service.price.discount"
                        :rules="rules.number"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-alert>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import db from "@/plugins/fb";
import PleaseWait from "@/components/templates/PleaseWait";
export default {
  components: {
    PleaseWait,
  },
  data: () => ({
    snackbar: false,
    timeout: 2000,
    loading: false,
    service: "",
    pleaseWait: true,
  }),

  computed: {
    selectedBusiness() {
      return this.$store.getters.getCurrentBusiness;
    },

    ownersBusinesses() {
      return this.$store.getters.getBusinesses;
    },

    selectedBusinessName() {
      let busId = this.ownersBusinesses.find(
        (item) => item.id == this.selectedBusiness
      );
      let busName = busId.businessName;
      return busName;
    },
    rules() {
      return {
        emptyField: [(v) => !!v || this.$t("this field is required")],
        number: [
          (v) => !!v || this.$t("this field is required"),
          (v) => !isNaN(v) || this.$t("please enter a number"),
        ],
      };
    },
  },

  mounted() {
    this.getService();
  },

  methods: {
    getService() {
      db.collection("services")
        .doc(this.$route.params.id)
        .get()
        .then((doc) => {
          this.service = doc.data();
          this.pleaseWait = false;
        })
        .catch((error) => {
          console.log("Error getting  document:", error);
        });
    },

    saveUpdate() {
      db.collection("services")
        .doc(this.$route.params.id)
        .update(this.service)
        .then(() => {
          this.loading = false;
          this.snackbar = true;
          setTimeout(() => this.goBack(), 2000);
        })
        .catch((error) => {
          console.error("Error updating document: ", error);
        });
    },

    digits(event) {
      let keyCode = event.keyCode ? event.keyCode : event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 43) {
        // 46 is dot 43 is +
        event.preventDefault();
      }
    },

    goBack() {
      this.$router.push({
        name: "ViewServiceDetails",
        params: { id: this.$route.params.id },
      });
    },
  },
};
</script>

<style></style>
